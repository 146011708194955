import $ from 'legacy/vendor/jquery.custom'
import GAReporter from 'lib/GoogleAnalytics/GAReporter'

import ComponentLoader from 'legacy/lib/ComponentLoader'

export default class NativeShareButton extends ComponentLoader {
  static mount() {
    if (navigator.share) $(document).on('click', '.gtm-native-share-sheet', this.onClick)
  }

  static initialize() {
    if (!navigator.share) $('.gtm-native-share-sheet').remove()
  }

  static onClick = async (event) => {
    event.preventDefault()

    const data = event.currentTarget.dataset
    const url  = data.url || document.querySelector('link[rel=canonical]').href || window.location.href

    await navigator.share({ text: data.text, url })
    GAReporter.pushEventToGTM({ event: 'share' })
  }
}
