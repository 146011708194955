import $                       from 'legacy/vendor/jquery.custom'
import GAReporter              from 'lib/GoogleAnalytics/GAReporter'

import ComponentLoader         from 'legacy/lib/ComponentLoader'
import ModalFocus              from 'legacy/lib/ModalFocus'

import AjaxLoadedContent       from 'legacy/views/global/AjaxLoadedContent'
import ClearableInput          from 'legacy/views/global/ClearableInput'
import Header                  from 'legacy/views/global/Header'
import NativeShareButton       from 'legacy/views/global/NativeShareButton'
import Notifications           from 'legacy/views/global/Notifications'
import SaveSearch              from 'legacy/views/global/SaveSearch'
import SaveSearchButton        from 'legacy/views/global/SaveSearchButton'
import SaveSearchForm          from 'legacy/views/global/SaveSearchForm'
import SearchAutoComplete      from 'legacy/views/global/SearchAutoComplete'
import SearchForm              from 'legacy/views/global/SearchForm'
import TimeSinceDate           from 'legacy/views/global/TimeSinceDate'

export default class DefaultPage extends ComponentLoader {
  static childComponents = [
    AjaxLoadedContent,
    ClearableInput,
    Header,
    NativeShareButton,
    Notifications,
    SaveSearch,
    SaveSearchButton,
    SaveSearchForm,
    SearchAutoComplete,
    SearchForm,
    TimeSinceDate,

    // Lower Priority
    GAReporter,
  ]

  static mount() {
    // HACK: Leak variables into global namespace
    window.$      = $
    window.jQuery = $
  }

  static initialize() {
    ModalFocus.captureTabEvent()
  }
}
