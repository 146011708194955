import ComponentLoader from 'legacy/lib/ComponentLoader'

export default class GAReporter extends ComponentLoader {
  static initialize() {
    if (SERVER_SIDE) return
    const analytics = window.estately.analytics
    analytics.gtm_data_layer.forEach(event => this.pushEventToGTM(event))
  }

  // Pushes events to google tag manager
  static pushEventToGTM(dataLayer) {
    if ('event' in dataLayer) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(dataLayer)
    }
  }
}
