import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader          from 'legacy/lib/ComponentLoader'
import EstatelyEvents           from 'legacy/lib/EstatelyEvents'

export default class Notifications extends ComponentLoader {
  static initialize() {
    this.$notificationsBtn = $('[data-toggle="modal"][data-target="#js-notifications-center"]')
    this.$notifications    = $('#js-notifications-center')
    this.$unreadCircle     = this.$notificationsBtn.find('.js-notifications-circle')
    this.notificationsUrl  = this.$notifications.data('url')

    this.lazyUpdateNotifications()

    $(document).on(EstatelyEvents.STATE_CHANGE.LOGIN, () => this.lazyUpdateNotifications())

    this.$notificationsBtn.on('mouseover click', () => {
      this.$notificationsBtn.trigger(EstatelyEvents.USER.WILL_INTERACT)
    })

    this.$notifications
      .on(EstatelyEvents.SAVE_SEARCH.SUCCESS, () => this.onSaveSearchFromNotifications())
      .on('show.bs.modal', () => this.onOpen())
      .on('click', '.notifications-list .notification .listing-card', (event) => {
        window.location.href = event.currentTarget.dataset.path
      })
  }

  static onSaveSearchFromNotifications() {
    this.$notifications.modal('hide')
  }

  static lazyUpdateNotifications() {
    // Delay fetching the unread count (we already render from cached sessionStorage in _header.html.haml)
    requestIdleCallback(() => this.updateUnreadCount())

    this.$notificationsBtn.one(EstatelyEvents.USER.WILL_INTERACT, () => this.fetchNotifications())
  }

  static fetchNotifications = async () => {
    const content = await $.get(this.notificationsUrl)
    requestAnimationFrame(() => {
      $('.js-notifications-content')
        .addClass('fade')
        .html(content)
        .trigger(EstatelyEvents.AJAX_CONTENT.LOADED, [content])
        .addClass('in')
    })
  }

  static onOpen = async () => {
    if (window.estately.data.is_user_logged_in) await $.post('/notifications/viewed')

    this.renderUnreadCircle(0) // clear unread
  }

  static async updateUnreadCount() {
    if (!window.estately.data.is_user_logged_in) return

    const { unread } = await $.get('/notifications/count')

    this.renderUnreadCircle(unread)
  }

  static renderUnreadCircle(count) {
    requestAnimationFrame(() => {
      sessionStorage.setItem('notifications-unread-count', count || '')
      if (count === 0) {
        this.$unreadCircle.hide()
      } else {
        this.$unreadCircle.show()
      }
    })
  }
}
