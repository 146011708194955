import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader from 'legacy/lib/ComponentLoader'

export default class SearchForm extends ComponentLoader {
  static initialize() {
    $('#search-form').on('submit', this.onSubmit)
  }

  static onSubmit = (event) => {
    event.preventDefault()

    const searchPath = window.estately.data.is_mobile ? 'search' : 'map'
    const params     = new URLSearchParams(new FormData(event.target))

    window.location = `/${searchPath}?${params}`
  }
}
